<template>
    <b-row>
        <b-col lg="12">
            <b-card-actions title="媒体列表" @refresh="refreshStop" ref="refreshCard">
                <b-row>
                    <b-col lg="6" class="mb-1">
                        <b-button-group>
                            <b-button variant="primary" class="btn-icon" v-b-toggle.sidebar-1
                                @click="clearfrom"><feather-icon icon="PlusCircleIcon" /></b-button>
                            <!-- <b-button variant="primary" class="btn-icon" @click="selectAllRows()"><feather-icon
                                    icon="CheckCircleIcon" />&nbsp;<span class="align-middle">{{ select_mode }}</span></b-button>
                            <b-button variant="primary" class="btn-icon" @click="deleteAllRows()"><feather-icon
                                    icon="Trash2Icon" />&nbsp;<span class="align-middle">批量删除</span></b-button> -->

                        </b-button-group>
                    </b-col>

                    <!-- <b-col lg="6" class="mb-1">
                        <b-input-group class="input-group-merge">
                            <b-input-group-prepend is-text><feather-icon icon="SearchIcon" /></b-input-group-prepend>
                            <b-form-input placeholder="楼栋号" v-model="search_ld" />
                            <b-form-input placeholder="住宅号" v-model="search_name" />
                            <b-input-group-append><b-button variant="outline-primary"
                                    @click="get_search()">检索</b-button></b-input-group-append>
                        </b-input-group>
                    </b-col> -->
                </b-row>

                <b-table ref="selectableTable" class="tabledata" striped hover responsive="sm" :items="items"
                    :fields="fields" sticky-header no-border-collapse @row-selected="onRowSelected" selectable
                    select-mode="multi" small>

                    <template #cell(icon)="data">
                        <feather-icon :icon="data.item.icon" size="20" />
                    </template>
                    <!-- 设置 -->
                    <template #cell(actions)="data">
                        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                            </template>

                            <!-- <b-dropdown-item @click="get_edit(data.item.id)" v-b-toggle.sidebar-1>
                                <feather-icon icon="EditIcon" />
                                <span class="align-middle ml-50">编辑</span>
                            </b-dropdown-item> -->

                            <b-dropdown-item @click="get_del(data.item.name)">
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50">删除</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>


                </b-table>
                <b-row>
                    <b-col lg="12">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" first-number last-number
                            prev-class="prev-item" next-class="next-item" class="mb-0">
                            <template #prev-text> <feather-icon icon="ChevronLeftIcon" size="18" /></template>
                            <template #next-text> <feather-icon icon="ChevronRightIcon" size="18" /></template>
                        </b-pagination>
                    </b-col>
                </b-row>


                <b-sidebar id="sidebar-1" shadow bg-variant="white" right backdrop width="auto" :title="titledata">
                    <b-card class="add_card">
                        <b-form>
                            <validation-observer ref="simpleRules">

                                <b-row>
                                    <b-col cols="12">
                                        <b-form-group label="音频" label-for="h-number" label-cols-md="4">
                                            <validation-provider #default="{ errors }" name="menu_icon" rules="required">
                                                <!-- <b-form-file  v-model="FormData.url" class="mt-1" plain /> -->
                                                <input type="file" name="" id="" accept=".mp3,audio/mpegm"
                                                    @change="changeHalld($event)">

                                            </validation-provider>
                                        </b-form-group>
                                    </b-col>
                                </b-row>

                                <b-row>
                                    <b-col cols="12" style="text-align:center">
                                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
                                            @click="save()">
                                            保存
                                        </b-button>
                                    </b-col>


                                </b-row>
                            </validation-observer>
                        </b-form>
                    </b-card>
                </b-sidebar>
            </b-card-actions>
        </b-col>
    </b-row>
</template>
  
<script>
import {
    BCard, BBadge, BCardText, BPagination, BButton, BPopover, BTable, BRow, BCol, BDropdown, BDropdownItem, BSidebar, VBToggle, BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BFormTags,
    BInputGroupAppend, BButtonGroup,
    BInputGroup, BInputGroupPrepend, BFormSelect, BFormDatepicker, BFormFile
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BListGroupItem, BAvatar } from 'bootstrap-vue'
import draggable from 'vuedraggable'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import axios from 'axios'
export default {
    components: {
        BFormDatepicker,
        BFormFile,
        BFormSelect,
        BBadge,
        BSidebar,
        VBToggle,
        BDropdown,
        BDropdownItem,
        BRow,
        BCol,
        BTable,
        BCard,
        BCardText,
        BCardActions,
        BButton,
        BPopover,
        BPagination,
        AppCollapse,
        AppCollapseItem,
        draggable,
        BListGroupItem,
        BAvatar,
        Prism,
        vSelect,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BFormTags,
        BInputGroupAppend,
        BButtonGroup,
        BInputGroup,
        BInputGroupPrepend,
        ToastificationContent,
        VuePerfectScrollbar,
        ValidationProvider,
        ValidationObserver,
        BFormFile

    },
    directives: {
        'b-toggle': VBToggle,
        Ripple,
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'ID', },
                { key: 'name', label: '名称', },
                { key: 'created_at', label: '创建时间', },
                { key: 'actions', label: '设置', },

            ],
            perfectScrollbarSettings: {
                maxScrollbarLength: 150,
                wheelPropagation: false,
            },
            planOptions: [
                { label: '隐藏', value: '0' },
                { label: '显示', value: '1' },
            ],
            items: [],
            table_height: 400,
            currentPage: 1,
            perPage: 20,
            rows: 0,
            list: [],
            selected: [],
            select_all: 0,
            select_mode: "全选",
            nameFilter: '',
            roleFilter: '',
            planFilter: '',
            search_name: '',
            search_ld: '',
            detailstData: {},
            editData: {},
            // pid: 0,

            FormData: {
                act: '',
                id: '',
                url: ''

            },
            flg: true,
            buildData: '',
            buildoptions: [],
            titledata: '',
            file2: ''
        }
    },
    created() {

    },
    mounted() {
        this.get_select()
        this.get_data()
    },
    watch: {
        currentPage: function (oldV, newV) {
            this.get_data()
        },
    },
    methods: {
        clearfrom() {
            this.FormData = {
                act: '',
                id: '',
                url: ''
            }
            this.buildData = ''

            this.get_select()

        },

        get_search() {
            this.get_data()
        },
        getStause(val) {
            this.planFilter = val
        },
        refreshStop() {
            this.search_name = ""
            this.get_data()
        },
        del_data(val) {
            let dataObj = {
                act: "del",
                name: '//' + val,
            }
            this.$http.post('/svr/boardcast_voice.php', dataObj)
                .then(res => {
                    this.showToast('success', '删除成功')
                    this.get_data()
                })
        },
        deleteAllRows(id) {
            // let ids = this.set_delids(id)
            // if (!this.selected.length > 0 && !id > 0) {
            //     this.showToast('danger', '请先选择您要删除的数据!')
            //     return
            // }
            this.$swal({
                title: '确定删除数据吗?',
                text: "此操作将不可逆,请谨慎操作",
                showCancelButton: true,
                cancelButtonText: '取消',
                confirmButtonText: '我确定!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    this.del_data(id)
                }
            })
        },
        showToast(variant, text) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: '系统提示',
                    icon: 'BellIcon',
                    text: text,
                    variant,
                },
            })
        },
        // onRowSelected(items) {
        //     this.selected = items
        //     if (items.length > 0) {
        //         this.showToast('success', '选中了' + (items.length) + '条数据!')
        //     }
        // },
        // selectAllRows() {
        //     if (this.select_all == 1) {
        //         this.clearSelected()
        //         return
        //     }
        //     this.$refs.selectableTable.selectAllRows()
        //     this.select_all = 1
        //     this.select_mode = "取消全选"
        // },
        // clearSelected() {
        //     this.$refs.selectableTable.clearSelected()
        //     this.select_all = 0
        //     this.select_mode = "全选"
        // },
        get_data() {
            let dataObj = {
                act: "lst",
                pge: this.currentPage,
                cnt: this.perPage,

            }
            this.$nextTick(() => {
                this.$refs["refreshCard"].showLoading = true
            })
            this.$http.post('/svr/boardcast_voice.php', dataObj)
                .then(res => {
                    this.items = res.data.lst
                    this.rows = res.data.cnt_all
                    setTimeout(() => {
                        this.$refs["refreshCard"].showLoading = false
                    }, 300)
                })
        },
        get_del(val) {
            console.log(val, '111');
            this.deleteAllRows(val)
        },
        get_select() {
            let obj = {
                act: 'get_build'
            }
            this.$http.post('svr/data_build.php', obj).then(res => {
                if (res.code == 20000) {
                    console.log(res, '下拉数据');
                    this.buildoptions = res.data
                }
            })
        },
        get_edit(e) {
            let getdata = {
                act: 'get',
                id: e
            }
            this.$http.post('svr/data_house.php', getdata).then(res => {
                if (res.code == 20000) {
                    this.FormData = res.data
                    console.log(res.data, '编辑');
                    this.buildData = this.FormData.pid
                }
            })

        },
        //上传音视频
        changeHalld(event) {
            let that = this
            var file = event.target.files[0]        //获取文件         
            const formData = new FormData();
            formData.append("fle", file);
            axios({
                method: "post",
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                url: "http://yunhu.njqianyan.cn/wlwpt/svr/uld.php?act=file",
                data: formData,
            })
                .then(function (response) {
                    console.log(response, '123465');
                    that.FormData.url = response.data.url
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        save() {
            // this.$refs.simpleRules.validate().then(success => {
            //     if (success) {

            if (this.FormData.id == '') {
                this.FormData.act = 'add'
            } else {
                this.FormData.act = 'mod'
            }
            console.log(this.FormData, '需要保存的数据');
            this.$http.post('/svr/boardcast_voice.php', this.FormData).then(res => {
                if (res.code == 20000) {
                    this.showToast('success', '编辑成功!')
                    this.get_data()
                    this.FormData.url = ''
                }
            })
            //     }
            // })
        },
        set_delids(id) {
            let val = this.selected
            let ids = []
            val.forEach(item => {
                ids.push(item.id)
            })
            ids = ids.join();
            if (id) {
                ids = id;
            }
            return ids;
        },
        resolveUserStatusVariant(flg) {
            if (flg === '0') return 'warning'
            if (flg === '1') return 'success'
            return 'primary'
        }
    },
}
</script>
<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';

.list-group-item {
    transition: all 1s
}

.tabledata {
    border-radius: 3px;
}

.b-table-sticky-header {
    max-height: calc(100vh - 390px) !important;
}

.custom-select {
    padding: 0.438rem 0rem 0.438rem 0.6rem;
    width: 3.4rem;
}

.b-table-selectable {
    .feather {
        font-size: 1.3rem;
    }
}

.filter {
    margin-bottom: 2rem;
}

.table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th {
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 1.1rem;
    font-weight: 600;
}

.card {
    margin-bottom: 0 !important;
}

.menu_main {
    height: calc(100vh - 328px) !important;
}

.add_card {
    height: calc(100vh - 70px) !important;
}
</style>
  
  